import { mainClient } from '~/network';
import config from '~/config';

const state = {
    user: null,

    getUserIsLoading: false,
    getUserError: null,

    getUserInfoIsLoading: false,

    checkEmailIsLoading: false,

    authResult: null,
    authIsLoading: false,
    authError: null,

    updateIsLoading: false,
    updateError: null,

    infoUpdateIsLoading: false,
    infoUpdateError: null,

    changePasswordIsLoading: false,
    changePasswordError: null,

    logoutIsLoading: false,
    logoutError: null,

    resetPasswordIsLoading: false,
    resetPasswordError: null,
    resetPasswordIsReset: false,

    setPasswordIsLoading: false,
    setPasswordError: null,
    setPasswordIsReset: false,
};

const getters = {
    user(state) {
        return state.user;
    },
    state(state) {
        return state;
    },
    isLoading(state) {
        return state.getUserIsLoading ||
            state.authIsLoading ||
            state.updateIsLoading ||
            state.changePasswordIsLoading ||
            state.logoutIsLoading ||
            state.checkEmailIsLoading;
    },
};

const mutations = {
    setCheckEmailIsLoading(state, data) {
        state.checkEmailIsLoading = data;
    },
    setStateValue(state, { entity, value }) {
        state[entity] = value;
    },
};

const actions = {
    async getUser({ state, commit }) {
        if (state.getUserIsLoading) {
            return;
        }

        state.getUserIsLoading = true;

        const { data, error } = await mainClient.user.checkLogin();
        if (error) {
            state.user = null;
        } else if (data) {
            state.user = { ...state.user, ...data };
        }

        state.getUserIsLoading = false;
    },
    async getUserInfo({ state, commit }) {
        if (state.getUserInfoIsLoading) {
            return;
        }

        state.getUserInfoIsLoading = true;

        const { data, error } = await mainClient.user.info();
        if (error) {
            state.user = null;
        } else if (data) {
            state.user = { ...state.user, ...data };
        }

        state.getUserInfoIsLoading = false;
    },
    async auth({ state, commit, dispatch }, payload) {
        if (state.authIsLoading) {
            return;
        }

        state.authIsLoading = true;
        state.authError = null;

        const { data, error, status } = await mainClient.user.auth(payload);

        if (error) {
            console.error(error, status);
            state.authError = error;
            state.authResult = null;
        } else if (data) {
            state.authResult = data;
            state.user = data;
            dispatch('getCart');
        }

        state.authIsLoading = false;
    },
    /* TODO double code */
    async getCart({ state, getters }) {
        if (getters.isLoading) {
            return;
        }
        state.getCartIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.getBasket({ basket_key: config.cart.keys.common });
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module getCart action');
            state.getCartResult = null;
            state.getCartError = error;
        } else {
            state.getCartResult = data;
            state.getCartError = null;
        }
        state.getCartIsLoading = false;
    },
    async logout({ state, commit }, payload) {
        if (state.logoutIsLoading) {
            return;
        }

        commit('setStateValue', { entity: 'logoutIsLoading', value: true });
        commit('setStateValue', { entity: 'logoutError', value: null });

        const { error, status } = await mainClient.user.logout(payload);

        if (error) {
            console.error(error, status);
            console.error('Error above relates to store/user logout action');
            commit('setStateValue', { entity: 'logoutError', value: error });
        } else {
            commit('setStateValue', { entity: 'user', value: null });
        }

        commit('setStateValue', { entity: 'logoutIsLoading', value: false });
    },
    async register({ state, commit }, payload) {
        if (state.registerIsLoading) {
            return;
        }

        commit('setStateValue', { entity: 'registerIsLoading', value: true });
        commit('setStateValue', { entity: 'registerError', value: null });

        const { data, error, status } = await mainClient.user.register(payload);

        if (error) {
            console.error(error, status);
            console.error('Error above relates to store/user register action');
            commit('setStateValue', { entity: 'registerError', value: error });
        } else if (data) {
            state.user = { ...state.user, ...data };
        }

        commit('setStateValue', { entity: 'registerIsLoading', value: false });
    },
    async update({ state, commit }, payload) {
        if (state.updateIsLoading) {
            return;
        }

        commit('setStateValue', { entity: 'updateIsLoading', value: true });
        commit('setStateValue', { entity: 'updateError', value: null });

        const { data, error, status } = await mainClient.user.update(payload);

        if (error) {
            console.error(error, status);
            commit('setStateValue', { entity: 'updateError', value: error });
        } else if (data) {
            state.user = { ...state.user, ...data };
        }

        commit('setStateValue', { entity: 'updateIsLoading', value: false });
    },
    async infoUpdate({ state }, payload) {
        if (state.infoUpdateIsLoading) {
            return;
        }

        state.infoUpdateIsLoading = true;
        state.infoUpdateError = null;

        const { data, error, status } = await mainClient.user.infoUpdate(payload);

        if (error) {
            console.error(error, status);
            state.infoUpdateError = error;
        } else if (data) {
            state.infoUpdateError = null;
            state.user = { ...state.user, ...data };
        }

        state.infoUpdateIsLoading = false;
    },
    async changePassword({ state, commit }, payload) {
        if (state.changePasswordIsLoading) {
            return;
        }

        commit('setStateValue', { entity: 'changePasswordIsLoading', value: true });
        commit('setStateValue', { entity: 'changePasswordError', value: null });

        const { error, status } = await mainClient.user.changePassword(payload);

        if (error) {
            console.error(error, status);
            console.error('Error above relates to store/user changePassword action');
            commit('setStateValue', { entity: 'changePasswordError', value: error });
        }

        commit('setStateValue', { entity: 'changePasswordIsLoading', value: false });
    },
    async resetPassword({ state, commit }, payload) {
        if (state.resetPasswordIsLoading) {
            return;
        }

        commit('setStateValue', { entity: 'resetPasswordIsLoading', value: true });
        commit('setStateValue', { entity: 'resetPasswordError', value: null });
        commit('setStateValue', { entity: 'resetPasswordIsReset', value: false });

        const { error, status } = await mainClient.user.resetPassword(payload);
        if (error) {
            console.error(error, status);
            console.error('Error above relates to store/user resetPassword action');
            commit('setStateValue', { entity: 'changePasswordError', value: error });
        } else {
            commit('setStateValue', { entity: 'resetPasswordIsReset', value: true });
        }

        commit('setStateValue', { entity: 'resetPasswordIsLoading', value: false });
    },
    async setPassword({ state, commit }, payload) {
        if (state.setPasswordIsLoading) {
            return;
        }

        commit('setStateValue', { entity: 'setPasswordIsLoading', value: true });
        commit('setStateValue', { entity: 'setPasswordError', value: null });
        commit('setStateValue', { entity: 'setPasswordIsReset', value: false });

        const { error, status } = await mainClient.user.setPassword(payload);
        if (error) {
            console.error(error, status);
            console.error('Error above relates to store/user setPassword action');
            commit('setStateValue', { entity: 'changePasswordError', value: error });
        } else {
            commit('setStateValue', { entity: 'setPasswordIsReset', value: true });
        }

        commit('setStateValue', { entity: 'setPasswordIsLoading', value: false });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
