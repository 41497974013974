/**
 * @param {Object} data
 * @param {string} data.email
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {string} data.phone
 * @param {string} [data.personal_discount]
 * @param {string} [data.accumulative_discount]
 * @returns {Object}
 */
export const userBasic = (data) => {
    const result = {
        name: [data.first_name, data.last_name].filter(x => !!x).join(' '),
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
    };

    if (data.personal_discount) {
        result.personalDiscount = parseInt(data.personal_discount);
        result.discount = result.personalDiscount;
    }
    if (data.accumulative_discount) {
        result.accumulativeDiscount = parseInt(data.accumulative_discount);
        if (result.accumulativeDiscount > (result.discount ?? 0)) {
            result.discount = result.accumulativeDiscount;
        }
    }
    if (data.phone) {
        result.phone = data.phone;
        result.username = data.phone;
    }
    if (data.username) {
        result.username = data.username;
    }
    return result;
};

export const getLogin = (data) => {
    return {
        ...userBasic(data.result),
        id: data.result.id,
    };
};

export const getUserInfo = (data) => {
    return {
        ...userBasic(data),
        id: data.id,
    };
};

export const update = (data) => {
    return userBasic(data);
};

export const info = (data) => {
    return {
        phone: data.phone,
    };
};

export default {
    getLogin,
    getUserInfo,
    update,
};
