<template>
    <div class="page-dashboard-info-form">
        <div class="page-dashboard-info-form__item" v-if="user && user.discount">
            <div class="page-dashboard-info-form__title">
                Ваша скидка по программе лояльности
            </div>
            <div style="margin-bottom: 20px">{{user.discount}}%</div>
            <div style="margin-bottom: 20px">
                Узнать условия применения персональной скидки вы можете
                <a href="https://pyeoptics.com/pages/loyalty-programm/" target="_blank">здесь</a>.
                Если у вас остались вопросы,
                напишите в <a href="#chatra_trigger">чат.</a>
            </div>
            <div>
                * Скидка обновляется через сутки после завершения покупки.
            </div>
        </div>
        <div class="page-dashboard-info-form__item">
            <page-dashboard-info-form-data />
        </div>
        <div class="page-dashboard-info-form__item">
            <page-dashboard-info-form-info />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-dashboard-info-user',
    computed: {
        ...mapState('user', [
            'user',
        ]),
    },
    mounted() {
        this.$store.dispatch('user/getUserInfo');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-info-form {
    padding: 0 20px;
    &__item {
        & ~ & {
            margin-top: 40px;
        }
    }
    &__title {
        margin-bottom: 20px;

        text-transform: uppercase;
    }
}
</style>
