import { render, staticRenderFns } from "./cart.vue?vue&type=template&id=621ab4fe&scoped=true&"
import script from "./cart.vue?vue&type=script&lang=js&"
export * from "./cart.vue?vue&type=script&lang=js&"
import style0 from "./cart.vue?vue&type=style&index=0&id=621ab4fe&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621ab4fe",
  null
  
)

export default component.exports