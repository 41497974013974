import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const getProducts = (params = {}) => {
    return client.request({
        url: config.api.shop.products,
        method: 'GET',
        params,
        paramsSerializer: utils.network.customParamsSerializer,
        transformRequest: transforms.requests.getProducts,
        transformResponse: transforms.responses.getProducts,
    });
};

export const search = (params = {}) => {
    const query = params.query && params.query !== '' ? params.query : '';
    return client.request({
        url: `${config.api.shop.search}?query=${query}`,
        method: 'GET',
        transformResponse: transforms.responses.search,
    });
};

// Get Product By ID
export const getProduct = (params = {}) => {
    return client.request({
        url: `${config.api.shop.products}${params.id}/`,
        method: 'GET',
        transformResponse: transforms.responses.product,
    });
};

// Get Availability By Url
export const getAvailability = (params) => {
    return client.request({
        url: '',
        baseURL: params.url,
        method: 'GET',
    });
};

export const getFilters = ({ collectionId, ...params }) => {
    return client.request({
        url: config.api.shop.filters.replace('{collectionId}', collectionId || 0),
        method: 'GET',
        params,
        transformResponse: transforms.responses.getFilters,
    });
};

// Get Stockrecords By Url
export const getStockrecords = (params) => {
    return client.request({
        url: '',
        baseURL: params.url + '?page_size=100',
        method: 'GET',
    });
};

// Get Basket Preview
export const getBasket = (params = {}) => {
    return client.request({
        url: config.api.shop.basket.get,
        method: 'GET',
        params,
        transformResponse: transforms.responses.getBasket,
    });
};

export const getPaymentMethods = (params = {}) => {
    return client.request({
        url: config.api.shop.paymentMethods,
        method: 'GET',
        params,
        transformResponse: transforms.responses.getPaymentMethods,
    });
};

export const getAreas = () => {
    return client.request({
        url: config.api.shop.areas,
        method: 'GET',
    });
};

export const getShippingMethods = (params = {}) => {
    return client.request({
        url: config.api.shop.shippingMethods,
        method: 'GET',
        params,
        transformResponse: transforms.responses.getShippingMethods,
    });
};

export const getDeliveryCode = (data = {}) => {
    return client.request({
        url: config.api.shop.getDeliveryCode,
        method: 'POST',
        data,
        transformResponse: transforms.responses.getShippingMethods,
    });
};

export const getShippingPrice = (data = {}) => {
    return client.request({
        url: config.api.shop.shippingMethods + '?basket_key=cart',
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
        transformRequest: transforms.requests.getShippingPrice,
        transformResponse: transforms.responses.getShippingPrice,
    });
};

export const getShippingCityCdekCode = (data = {}) => {
    return client.request({
        url: config.api.shop.getDeliveryCode,
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
        transformRequest: transforms.requests.getShippingCityCdekCode,
        transformResponse: transforms.responses.getShippingCityCdekCode,
    });
};

export const getStores = () => {
    return client.request({
        url: config.api.shop.stores,
        method: 'GET',
    });
};

export const getOrders = (params = {}) => {
    // return fake result:
    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve({
    //             data: transforms.responses.getOrders({
    //                 count: 1,
    //                 results: [
    //                     {
    //                         'number': 'IM_4431519_93730',
    //                         'basket': null,
    //                         'url': 'https://pyeoptics.com/shop/orders/25896/',
    //                         'lines': 'https://pyeoptics.com/shop/orders/25896/lines/',
    //                         'order_information': {
    //                             'need_lenses': false,
    //                             'phone_number': null,
    //                             'tracking_number': null,
    //                             'tracking_url': null,
    //                             'cdek_tracking_url': null,
    //                             'pickup_address': null,
    //                             'gift_voucher_order': null,
    //                             'gift_email_from': 'daria@pyeoptics.com',
    //                             'gift_email_to': 'daria@pyeoptics.com',
    //                             'is_paid': false,
    //                             'communication': null,
    //                             'status': {
    //                                 'name': 'Принят',
    //                                 'description': 'Скоро начнем обработку заказа! Свяжемся с вами в течение 24 часов',
    //                             },
    //                             'order_type': 'customer_order_manual',
    //                             'courier_service': null,
    //                             'shipping_cell_number': null,
    //                             'lens_data': {
    //                                 'add': null,
    //                                 'color': null,
    //                                 'dpp_os': null,
    //                                 'dpp_od_ou': null,
    //                                 'cyl_os_left': null,
    //                                 'sph_os_left': null,
    //                                 'axis_os_left': null,
    //                                 'cyl_od_right': null,
    //                                 'sph_od_right': null,
    //                                 'axis_od_right': null,
    //                             },
    //                             'gift_name_to': 'Тест Кому',
    //                             'gift_name_from': 'Тест От кого',
    //                         },
    //                         'demand_information': null,
    //                         'owner': 'https://pyeoptics.com/shop/users/100446/',
    //                         'billing_address': null,
    //                         'currency': 'Р',
    //                         'total_incl_tax': '1.00',
    //                         'total_excl_tax': '1.00',
    //                         'shipping_incl_tax': '0.00',
    //                         'shipping_excl_tax': '0.00',
    //                         'shipping_address': null,
    //                         'shipping_method': 'Без доставки',
    //                         'shipping_code': 'no-shipping',
    //                         'status': 'Moi sklad status',
    //                         'guest_email': '',
    //                         'date_placed': '2024-08-23T15:39:00+03:00',
    //                         'payment_url': '',
    //                         'is_fitting': false,
    //                         'offer_discounts': [],
    //                         'voucher_discounts': [],
    //                         'order_lines': [
    //                             {
    //                                 'attributes': [],
    //                                 'url': 'https://pyeoptics.com/shop/orderlines/48299/',
    //                                 'product': {
    //                                     'url': '/shop/catalogue/podarochnyi-sertifikat_7913/',
    //                                     'id': 7913,
    //                                     'title': 'Подарочный сертификат',
    //                                     'structure': 'child',
    //                                     'attributes': [
    //                                         {
    //                                             'code': 'Тип сертификата',
    //                                             'value': 'Электронный',
    //                                         },
    //                                         {
    //                                             'code': 'Номинал',
    //                                             'value': '1',
    //                                         },
    //                                     ],
    //                                     'parent': 6102,
    //                                     'parent_attributes': [
    //                                         {
    //                                             'code': 'Тип товара',
    //                                             'value': 'Сертификат',
    //                                         },
    //                                     ],
    //                                     'images': [
    //                                         {
    //                                             'small': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/280xAUTO/egiftcard.jpg',
    //                                             'medium': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/1024xAUTO/egiftcard.jpg',
    //                                             'large': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/1500xAUTO/egiftcard.jpg',
    //                                             'extra_large': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/2000xAUTO/egiftcard.jpg',
    //                                             'gender': '',
    //                                         },
    //                                     ],
    //                                     'video': null,
    //                                     'video_preview': null,
    //                                     'price': 1.0,
    //                                     'discount': {
    //                                         'type': 'Absolute',
    //                                         'value': 0,
    //                                     },
    //                                     'is_clipon': false,
    //                                 },
    //                                 'stockrecord': null,
    //                                 'quantity': 1,
    //                                 'price_currency': 'Р',
    //                                 'price_excl_tax': '1.00',
    //                                 'price_incl_tax': '1.00',
    //                                 'price_incl_tax_excl_discounts': '1.00',
    //                                 'price_excl_tax_excl_discounts': '1.00',
    //                                 'order': 'https://pyeoptics.com/shop/orders/25896/',
    //                             },
    //                         ],
    //                         'manual_order_lines': [],
    //                         'gift_voucher': null,
    //                         'applied_gift_voucher': {
    //                             'code': null,
    //                             'discount': 0.0,
    //                         },
    //                     },
    //                     {
    //                         'number': '4428986',
    //                         'basket': 'https://pyeoptics.com/shop/baskets/4328986/',
    //                         'url': 'https://pyeoptics.com/shop/orders/25887/',
    //                         'lines': 'https://pyeoptics.com/shop/orders/25887/lines/',
    //                         'order_information': {
    //                             'need_lenses': false,
    //                             'phone_number': '+79939604579',
    //                             'tracking_number': null,
    //                             'tracking_url': null,
    //                             'cdek_tracking_url': null,
    //                             'pickup_address': null,
    //                             'gift_voucher_order': {
    //                                 'code': 'IM_4428986_56263',
    //                                 'status': 'unused',
    //                                 'nominal': '666.00',
    //                                 'remainder': '666.00',
    //                             },
    //                             'gift_email_from': 'daria@pyeoptics.com',
    //                             'gift_email_to': null,
    //                             'is_paid': true,
    //                             'communication': 'В WhatsApp',
    //                             'status': {
    //                                 'name': 'Сертификат',
    //                                 'description': null,
    //                             },
    //                             'order_type': 'customer_order_website',
    //                             'courier_service': 'Без доставки',
    //                             'shipping_cell_number': null,
    //                             'lens_data': {
    //                                 'add': null,
    //                                 'color': null,
    //                                 'dpp_os': null,
    //                                 'dpp_od_ou': null,
    //                                 'cyl_os_left': null,
    //                                 'sph_os_left': null,
    //                                 'axis_os_left': null,
    //                                 'cyl_od_right': null,
    //                                 'sph_od_right': null,
    //                                 'axis_od_right': null,
    //                             },
    //                             'gift_name_to': 'Тест Сайта',
    //                             'gift_name_from': 'Разработчики',
    //                         },
    //                         'demand_information': {
    //                             'status': 'Принят',
    //                             'lens_data': '{"color": null, "sph_od_right": null, "sph_os_left": null, "dpp_od_ou": null, "dpp_os": null, "cyl_od_right": null, "cyl_os_left": null, "axis_od_right": null, "axis_os_left": null, "add": null}',
    //                             'applied_gift_voucher_number': null,
    //                             'courier_service': 'Без доставки',
    //                             'shipping_address': 'Country, Default City, Default Address',
    //                             'tracking_number': null,
    //                             'manual_order_lines': [
    //                                 {
    //                                     'product': {
    //                                         'url': '/shop/catalogue/podarochnyi-sertifikat_7913/',
    //                                         'id': 7913,
    //                                         'title': 'Подарочный сертификат',
    //                                         'structure': 'child',
    //                                         'attributes': [
    //                                             {
    //                                                 'code': 'Тип сертификата',
    //                                                 'value': 'Электронный',
    //                                             },
    //                                             {
    //                                                 'code': 'Номинал',
    //                                                 'value': '1',
    //                                             },
    //                                         ],
    //                                         'parent': 6102,
    //                                         'parent_attributes': [
    //                                             {
    //                                                 'code': 'Тип товара',
    //                                                 'value': 'Сертификат',
    //                                             },
    //                                         ],
    //                                         'images': [
    //                                             {
    //                                                 'small': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/280xAUTO/egiftcard.jpg',
    //                                                 'medium': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/1024xAUTO/egiftcard.jpg',
    //                                                 'large': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/1500xAUTO/egiftcard.jpg',
    //                                                 'extra_large': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/2000xAUTO/egiftcard.jpg',
    //                                                 'gender': '',
    //                                             },
    //                                         ],
    //                                         'video': null,
    //                                         'video_preview': null,
    //                                         'price': 1.0,
    //                                         'discount': {
    //                                             'type': 'Absolute',
    //                                             'value': 0,
    //                                         },
    //                                         'is_clipon': false,
    //                                     },
    //                                     'product_name': 'Подарочный сертификат',
    //                                     'quantity': 1,
    //                                     'line_price': '666.00',
    //                                     'line_price_before_discounts': '666.00',
    //                                 },
    //                             ],
    //                         },
    //                         'owner': 'https://pyeoptics.com/shop/users/100446/',
    //                         'billing_address': {
    //                             'id': 26868,
    //                             'country': 'https://pyeoptics.com/shop/countries/RU/',
    //                             'title': '',
    //                             'first_name': 'Даша Тест',
    //                             'last_name': 'ТМобайл',
    //                             'line1': 'Default Address',
    //                             'line2': '',
    //                             'line3': 'Default Country',
    //                             'line4': 'Default City',
    //                             'state': '',
    //                             'postcode': '10001',
    //                             'search_text': 'Даша Тест ТМобайл Default Address Default Country Default City 10001 Россия',
    //                         },
    //                         'currency': 'Р',
    //                         'total_incl_tax': '666.00',
    //                         'total_excl_tax': '666.00',
    //                         'shipping_incl_tax': '0.00',
    //                         'shipping_excl_tax': '0.00',
    //                         'shipping_address': {
    //                             'id': 26876,
    //                             'country': 'https://pyeoptics.com/shop/countries/RU/',
    //                             'title': '',
    //                             'first_name': 'Даша Тест',
    //                             'last_name': 'ТМобайл',
    //                             'line1': 'Default Address',
    //                             'line2': '',
    //                             'line3': 'Default Country',
    //                             'line4': 'Default City',
    //                             'state': '',
    //                             'postcode': '10001',
    //                             'search_text': 'Даша Тест ТМобайл Default Address Default Country Default City 10001 Россия',
    //                             'phone_number': '+79939604579',
    //                             'notes': '',
    //                         },
    //                         'shipping_method': 'Без доставки',
    //                         'shipping_code': 'no-shipping',
    //                         'status': 'Moi sklad status',
    //                         'guest_email': 'daria@pyeoptics.com',
    //                         'date_placed': '2024-08-23T13:30:55.243975+03:00',
    //                         'payment_url': '',
    //                         'is_fitting': false,
    //                         'offer_discounts': [],
    //                         'voucher_discounts': [],
    //                         'order_lines': [
    //                             {
    //                                 'attributes': [],
    //                                 'url': 'https://pyeoptics.com/shop/orderlines/48284/',
    //                                 'product': {
    //                                     'url': '/shop/catalogue/podarochnyi-sertifikat_7913/',
    //                                     'id': 7913,
    //                                     'title': 'Подарочный сертификат',
    //                                     'structure': 'child',
    //                                     'attributes': [
    //                                         {
    //                                             'code': 'Тип сертификата',
    //                                             'value': 'Электронный',
    //                                         },
    //                                         {
    //                                             'code': 'Номинал',
    //                                             'value': '1',
    //                                         },
    //                                     ],
    //                                     'parent': 6102,
    //                                     'parent_attributes': [
    //                                         {
    //                                             'code': 'Тип товара',
    //                                             'value': 'Сертификат',
    //                                         },
    //                                     ],
    //                                     'images': [
    //                                         {
    //                                             'small': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/280xAUTO/egiftcard.jpg',
    //                                             'medium': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/1024xAUTO/egiftcard.jpg',
    //                                             'large': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/1500xAUTO/egiftcard.jpg',
    //                                             'extra_large': 'https://image.pyeoptics.com/images/products/2023/02/07/1675786321/2000xAUTO/egiftcard.jpg',
    //                                             'gender': '',
    //                                         },
    //                                     ],
    //                                     'video': null,
    //                                     'video_preview': null,
    //                                     'price': 1.0,
    //                                     'discount': {
    //                                         'type': 'Absolute',
    //                                         'value': 0,
    //                                     },
    //                                     'is_clipon': false,
    //                                 },
    //                                 'stockrecord': 'https://pyeoptics.com/shop/stockrecords/391864/',
    //                                 'quantity': 666,
    //                                 'price_currency': 'Р',
    //                                 'price_excl_tax': '666.00',
    //                                 'price_incl_tax': '666.00',
    //                                 'price_incl_tax_excl_discounts': '666.00',
    //                                 'price_excl_tax_excl_discounts': '666.00',
    //                                 'order': 'https://pyeoptics.com/shop/orders/25887/',
    //                             },
    //                         ],
    //                         'manual_order_lines': [],
    //                         'gift_voucher': '/shop/gift-voucher/8463/',
    //                         'applied_gift_voucher': {
    //                             'code': null,
    //                             'discount': 0.0,
    //                         },
    //                     },
    //                 ],
    //             }),
    //             error: null,
    //             status: 200,
    //         });
    //     }
    //     , 1000);
    // });
    return client.request({
        url: config.api.shop.cabinet.orders,
        method: 'GET',
        params,
        transformRequest: transforms.requests.getOrders,
        transformResponse: transforms.responses.getOrders,
    });
};

export const getOrdersItem = ({ id, ...params }) => {
    return client.request({
        url: config.api.shop.cabinet.orders + id + '/',
        method: 'GET',
        params,
        transformResponse: transforms.responses.getOrdersItem,
    });
};

export const getOrderGiftCard = ({ url, ...params }) => {
    return client.request({
        url,
        method: 'GET',
        params,
        transformResponse: transforms.responses.getOrderGiftCard,
    });
};

export const addToCart = (data = {}) => {
    return client.request({
        url: config.api.shop.basket.add,
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
        transformResponse: transforms.responses.addToCart,
    });
};

export const removeLine = (data = {}) => {
    return client.request({
        url: data.url,
        baseURL: '',
        method: 'DELETE',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
    });
};

// Update product quantity
export const updateLine = (data = {}) => {
    return client.request({
        url: data.url,
        baseURL: '',
        method: 'PUT',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
    });
};

export const addToFitting = (params = {}) => {
    const data = {
        ...params,
        basket_key: 'fitting',
    };
    return client.request({
        url: config.api.shop.basket.add,
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
    });
};

export const checkout = (params = {}) => {
    const data = {
        ...params,
    };
    return client.request({
        url: config.api.shop.checkout,
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
    });
};

export const applyPromocode = (params = {}) => {
    const data = {
        basket_key: 'cart',
        ...params,
    };
    return client.request({
        url: config.api.shop.promocode.apply,
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
    });
};

export const removePromocode = (params = {}) => {
    const data = {
        basket_key: 'cart',
        ...params,
    };
    return client.request({
        url: config.api.shop.promocode.remove,
        method: 'POST',
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        data,
    });
};

export const getCertificates = (params = {}) => {
    return client.request({
        url: params.url,
        method: 'GET',
        transformResponse: transforms.responses.getProducts,
    });
};

export const getLenses = (params = {}) => {
    return client.request({
        url: config.api.shop.lenses.list,
        params,
        method: 'GET',
        transformResponse: transforms.responses.getLenses,
    });
};

export const uploadPrescription = (data = {}) => {
    return client.request({
        headers: {
            'enctype': 'multipart/form-data',
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        url: config.api.shop.prescription,
        data,
        method: 'POST',
    });
};
