<template>
    <section class="page-checkout-cart">
        <transition name="fade">
            <div class="page-checkout-cart__loader"
                v-if="isLoading"
                >
                <div class="page-checkout-cart__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="page-checkout-cart__list"
                v-if="items.length > 0"
                >
                <div class="page-checkout-cart__list-item"
                    v-for="(item, index) in items"
                    v-bind:key="index"
                    >
                    <common-card-cart
                        v-bind:product="item"
                        v-bind:ecommerce-location="'CheckoutCart'"
                        v-bind:is-fitting="mode === 'fitting'"
                    />
                </div>
                <div class="page-checkout-cart__list-item _warning"
                    v-if="sumNeededForFreeShipping"
                    >
                    ✦ Добавьте ещё на {{ sumNeededForFreeShipping }} для бесплатной курьерской доставки
                </div>
            </div>
        </transition>
        <transition name="fade">
            <template v-if="cart !== null && items.length > 0">
                <div class="page-checkout-cart__totals"
                    v-if="mode === 'checkout'"
                    v-bind:class="{ _with_remove: certificates.length > 0 || promocodes.length > 0 }"
                    >
                    <div class="page-checkout-cart__totals-row">
                        <div class="page-checkout-cart__totals-row-title"
                            v-text="'Сумма покупки:'"
                        />
                        <div class="page-checkout-cart__totals-row-value"
                            v-text="sumFormatted"
                        />
                    </div>
                    <template v-if="filteredDiscounts.length > 0">
                        <div class="page-checkout-cart__totals-row"
                            v-for="discount in filteredDiscounts"
                            v-bind:key="discount.description"
                            >
                            <div class="page-checkout-cart__totals-row-title">
                                {{ discount.description }}
                            </div>
                            <div class="page-checkout-cart__totals-row-value">
                                - <span v-text="formatPrice(discount.amount)" />
                            </div>
                        </div>
                    </template>
                    <template v-if="certificates.length > 0">
                        <div class="page-checkout-cart__totals-row"
                            v-for="certificate in certificates"
                            v-bind:key="certificate.voucher.code"
                            >
                            <div class="page-checkout-cart__totals-row-title">
                                Сертификат (<span v-text="certificate.voucher.code" />):
                            </div>
                            <div class="page-checkout-cart__totals-row-value">
                                - <span v-text="formatPrice(certificate.amount)" />
                            </div>
                            <div class="page-checkout-cart__totals-row-remove"
                                v-on:click="removeVoucher(certificate.voucher)"
                                >
                                <icon name="close" />
                            </div>
                        </div>
                    </template>
                    <template v-if="promocodes.length > 0">
                        <div class="page-checkout-cart__totals-row"
                            v-for="promocode in promocodes"
                            v-bind:key="promocode.voucher.code"
                            >
                            <div class="page-checkout-cart__totals-row-title">
                                Промокод (<span v-text="promocode.voucher.code" />):
                            </div>
                            <div class="page-checkout-cart__totals-row-value">
                                - <span v-text="formatPrice(promocode.amount)" />
                            </div>
                            <div class="page-checkout-cart__totals-row-remove"
                                v-on:click="removeVoucher(promocode.voucher)"
                                >
                                <icon name="close" />
                            </div>
                        </div>
                    </template>
                    <div class="page-checkout-cart__totals-row">
                        <div class="page-checkout-cart__totals-row-title">
                            Доставка:
                        </div>
                        <div class="page-checkout-cart__totals-row-value">
                            <span v-text="shippingPriceFormatted" />
                        </div>
                    </div>
                    <br />
                    <div class="page-checkout-cart__totals-row"
                        v-if="cart.total_incl_tax_excl_discounts !== cart.total_incl_tax && discountsSum > 0"
                        >
                        <div class="page-checkout-cart__totals-row-title">
                            Скидка:
                        </div>
                        <div class="page-checkout-cart__totals-row-value">
                            - <span v-text="discountFormatted" />
                        </div>
                    </div>
                    <div class="page-checkout-cart__totals-row _total">
                        <div class="page-checkout-cart__totals-row-title">
                            Итог:
                        </div>
                        <div class="page-checkout-cart__totals-row-value">
                            <span data-page-checkout-child="totalValue" v-text="totalFormatted"></span>
                        </div>
                    </div>
                </div>
                <div class="page-checkout-cart__totals _fitting"
                    v-else
                    >
                    <div class="page-checkout-cart__totals-row _uppercase">
                        <div class="page-checkout-cart__totals-row-title">
                            Примерка на дому:
                        </div>
                        <div class="page-checkout-cart__totals-row-value" v-text="'Бесплатно'" />
                    </div>
                    <div class="page-checkout-cart__totals-row _info">
                        <div class="page-checkout-cart__totals-row-title">
                            После получения посылки примеряйте оправы дома в течение 4-х дней.
                            Всё, что не подойдёт, бесплатно верните нам через ПВЗ СДЭК. <span class="page-checkout-cart__trigger" v-on:click="showFittingInfoSideblock">Подробнее</span>.
                        </div>
                    </div>
                </div>
            </template>
        </transition>
        <transition name="fade">
            <template v-if="isPromocodeAvailable">
                <div class="page-checkout-cart__promo">
                    <page-checkout-cart-promo />
                </div>
            </template>
        </transition>
        <div class="page-checkout-cart__help">
            Нужна помощь?
            <br/>
            <a v-bind:href="'tel:' + phone.value"
                v-text="phone.valueFormatted"
            />
            <br/>
            <ui-trigger v-on:click="$store.commit('common/chatraOpen')">Спросить в чате</ui-trigger>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import utils from '~/utils';
import { checkoutStepCart } from '~/utils/ecommerce';

export default {
    name: 'page-checkout-cart',
    inject: [ 'mode', 'cartModuleName' ],
    data: () => ({
        unpayedOrderWarningRequired: true,
    }),
    computed: {
        productsEcommerceData() {
            return this.$store.getters[this.cartModuleName + '/productsEcommerceData'];
        },
        removeFromCartIsLoading() {
            return this.$store.state[this.cartModuleName].removeFromCartIsLoading;
        },
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        shippingMethod() {
            return this.checkoutData.shippingMethod;
        },
        shippingCalculatedPrice() {
            return this.$store.getters['checkout/shippingCalculatedPrice'];
        },
        shippingPrice() {
            if (this.$store.getters['checkout/isShippingFree']) {
                return 0;
            } else if (this.shippingMethod?.type === 'fixed') {
                return this.shippingMethod.price || null;
            } else {
                return this.shippingCalculatedPrice?.price || null;
            }
        },
        shippingPriceFormatted() {
            if (this.shippingPrice === null) {
                return '-';
            } else if (this.shippingPrice === 0) {
                return 'Бесплатно';
            }
            return this.formatPrice(this.shippingPrice);
        },
        isLoading() {
            return this.$store.getters[this.cartModuleName + '/isLoading'];
        },
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
        phone() {
            return this.$store.getters['site/contacts'].phone;
        },
        items() {
            return this.$store.getters[this.cartModuleName + '/items'];
        },
        total() {
            const shipping = this.shippingPrice === null ? 0 : this.shippingPrice;
            return shipping + this.$store.getters[this.cartModuleName + '/total'];
        },
        sumNeededForFreeShipping() {
            if (this.mode !== 'checkout' || !this.$store.getters['checkout/sumToFreeShipping']) {
                return null;
            }
            return this.formatPrice(this.$store.getters['checkout/sumToFreeShipping']);
        },
        discountsSum() {
            return parseFloat(this.$store.getters['cart/discountsSum']);
        },
        discountFormatted() {
            if (!this.cart) {
                return '';
            }
            return this.formatPrice(this.discountsSum);
        },
        filteredDiscounts() {
            if (!this.cart || !Array.isArray(this.cart?.offer_discounts)) {
                return [];
            }
            return this.cart?.offer_discounts.filter(discount => discount.type === 'accumulative' || discount.type === 'personal');
        },
        totalFormatted() {
            if (!this.cart || !this.total) {
                return this.formatPrice(0);
            }
            return this.formatPrice(this.total);
        },
        sumFormatted() {
            if (!this.cart) {
                return '';
            }
            return this.formatPrice(this.cart.total_incl_tax_excl_discounts);
        },
        certificates() {
            return this.$store.getters['cart/certificates'];
        },
        promocodes() {
            return this.$store.getters['cart/promocodes'];
        },
        isPromocodeAvailable() {
            return this.mode === 'checkout' &&
                this.cart !== null &&
                this.items.length > 0 &&
                !this.cart.lines.some(x => x.product.isCertificate);
        },
    },
    methods: {
        formatPrice(price) {
            if (!this.cart) {
                return '';
            }
            return `${utils.formats.formatNumerWithSpaces(typeof price === 'number' ? price : parseFloat(price))} ₽`;
        },
        removeVoucher(voucher) {
            this.$store.dispatch(this.cartModuleName + '/removeVoucher', { vouchercode: voucher.code });
        },
        showFittingInfoSideblock() {
            this.$store.commit('sideblocks/push', 'delivery');
        },
    },
    mounted() {
        // analytics
        checkoutStepCart({
            total: this.cart.total,
            products: this.productsEcommerceData,
        }, this.mode === 'fitting');
    },
    watch: {
        removeFromCartIsLoading() {
            this.unpayedOrderWarningRequired = false;
        },
        items: {
            immediate: true,
            handler(newVal) {
                if (this.cart && newVal.length === 0) {
                    this.order = JSON.parse(window.localStorage.getItem(config.localStorage.prefix + config.localStorage.checkoutSuccessAffix) || 'null');
                    if (this.unpayedOrderWarningRequired && this.order && !this.order.isPaid) {
                        const props = {
                            title: `ЗАКАЗ #${this.order.id} НЕ ОПЛАЧЕН`,
                            text: `Ваш заказ оформлен, но не оплачен. Мы свяжемся с вами в ближайшее время. Вы можете самостоятельно оплатить заказ по ссылке из Личного кабинета или связаться с нами в чате, если у вас есть вопросы.`,
                            buttons: [
                                {
                                    text: 'Написать в чат',
                                    callback: () => {
                                        utils.common.chatraOpen({ callback: () => {
                                            utils.common.chatraSendAutoMessage('Здравствуйте! Готовы помочь по заказу ' + this.order.id);
                                        } });
                                    },
                                },
                            ],
                            onClose: () => { window.location.href = '/'; },
                        };
                        if (this.order.checkoutTimestamp && (this.order.checkoutTimestamp + 3 * 60 * 60 * 1000 + 60000) > Date.now()) {
                            props.buttons.push({
                                text: 'Оплатить в Личном кабинете',
                                href: config.urls.cabinet + '/#history-' + this.order.id,
                            });
                        }
                        this.$store.commit('modals/push', {
                            name: 'common',
                            props,
                        });
                    } else {
                        this.$store.commit('modals/push', {
                            name: 'common',
                            props: {
                                title: 'Нет товаров',
                                text: 'У вас в корзине нет товаров!',
                                buttons: [
                                    { text: 'Вернуться в каталог' },
                                ],
                                onClose: () => { window.location.href = config.urls.catalog; },
                            },
                        });
                    }
                }
            },
        },
        cart: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                if (newVal.hasCertificate && newVal.lines.reduce((acc, curr) => curr.product.isCertificate ? acc + 1 : acc, 0) > 1) {
                    this.$store.commit('modals/push', {
                        name: 'common',
                        props: {
                            title: 'Ошибка!',
                            text: 'У вас более одного сертификата в корзине!\nК сожалению, в каждом заказе может быть только один сертификат.',
                        },
                    });
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-cart {
    .typography-body-md();
    .container-paddings();

    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    &__trigger {
        cursor: pointer;

        .mixin-link-decoration();
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        display: flex;

        background-color: @color-gray-lighter;
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__list {
        .transition-fade();

        width: 100%;
        margin-bottom: 60px;
        &-item {
            position: relative;

            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            &._warning {
                color: @color-accent-warm;
            }
            & ~ & {
                margin-top: 20px;
            }
            &-image {
                flex: 0 0 auto;
                display: block;
                width: 140px;
                height: 80px;
                margin-right: 20px;

                text-decoration: none;

                background-color: @color-gray-lightest;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            &-info {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-right: 20px;
                &-line {
                    margin-bottom: 5px;
                    &-accent {
                        text-transform: uppercase;
                    }
                    &-old {
                        margin-right: 8px;

                        text-decoration: line-through;
                    }
                }
            }
            &-remove {
                position: absolute;
                top: -3px;
                right: -5px;
                z-index: 1;

                padding: 5px;

                font-size: 1rem;

                cursor: pointer;
            }
        }
    }
    &__totals {
        .transition-fade();

        margin-bottom: 20px;
        &._fitting {
            margin-bottom: 75px;
        }
        &-row {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding-right: 20px;
            ._with_remove > & {
                position: relative;
                padding-right: 66px;
            }
            &._uppercase {
                text-transform: uppercase;
            }
            &._info {
                margin-top: 8px;
            }
            &-title {
                &-trigger {
                    .mixin-link-decoration();

                    cursor: pointer;
                }
            }
            &-value {
                text-align: right;
                &-old {
                    margin-right: 14px;

                    text-decoration: line-through;
                }
            }
            &-remove {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;

                padding: 5px 20px;

                font-size: 1rem;

                cursor: pointer;
            }
            &_total {
                padding: 12px 19px 8px;
                margin-top: 39px;

                text-transform: uppercase;

                background-color: @color-gray-lightest;
            }
            &_hidden {
                display: none;
            }
        }
    }
    &__promo {
        .transition-fade();

        margin-bottom: 75px;
    }
    &__help {
        line-height: 2;
        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }
    }
    @media @media-md-down {
        &__help {
            display: none;
        }
    }
    @media @media-sm-down {
        min-height: 33vh;
        &__list {
            padding-right: 0;
            margin-bottom: 30px;
            margin-right: -20px;
            width: auto;
        }
        &__totals {
            &._fitting {
                margin-bottom: 0;
            }
            &-row {
                padding-right: 0;
                ._with_remove > & {
                    padding-right: 20px;
                }
                &-remove {
                    right: -20px;
                }
            }
        }
        &__promo {
            margin-bottom: 0;
        }
    }
}
</style>
