<template>
    <div class="sideblock-recipe">
        <div class="sideblock-recipe__text">
            Перед вами рецепт, по которому были изготовлены очки в этом заказе. Он действителен в течение 6 месяцев.
        </div>
        <div class="sideblock-recipe__text">
            Если вы проверяли зрение в PYE и вам нужен бланк рецепта, напишите в наш <a href="#chatra_trigger">чат поддержки</a> и мы пришлём его на почту.
        </div>
        <div class="sideblock-recipe__text">
            Если вы хотите повторить заказ, рекомендуем перепроверить зрение. У нас вы можете сделать это бесплатно — <a v-bind:href="eyeExamInfoHref" target="_blank">записаться на проверку зрения</a>.
        </div>

        <div class="sideblock-recipe__spacer" />
        <div class="sideblock-recipe__line" />
        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__title">
            Что обозначают параметры в рецепте на очки?
        </div>
        <div class="sideblock-recipe__spacer" />
        <div class="sideblock-recipe__title">
            Правый и левый глаз — OD и OS
        </div>
        <div class="sideblock-recipe__text">
            <ul>
                <li>OD — правый глаз (от лат. “oculus dexter”)</li>
                <li>OS — левый глаз (от лат. “oculus sinister”)</li>
            </ul>
        </div>

        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__table-item-column-icon">
            <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
        </div>
        <div class="sideblock-recipe__title">
            SPH (сфера)
        </div>
        <div class="sideblock-recipe__text">
            Это основная "сила" линзы, измеряется в диоптриях. Если просто: помогает глазам чётко видеть вдаль или вблизи.
        </div>
        <div class="sideblock-recipe__text">
            <ul>
                <li>Минус (–) — для тех, кто плохо видит вдаль (близорукость)</li>
                <li>Плюс (+) — для тех, кому трудно сфокусироваться вблизи (дальнозоркость)</li>
            </ul>
        </div>

        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__table-item-column-icon">
            <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
        </div>
        <div class="sideblock-recipe__title">
            CYL (цилиндр)
        </div>
        <div class="sideblock-recipe__text">
            Параметр для коррекции астигматизма — когда глаз видит немного смазано, линии могут двоиться или быть нечёткими.
            Цилиндр "подправляет форму" линзы, чтобы зрение стало ровным и чётким.
        </div>

        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__table-item-column-icon">
            <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
        </div>
        <div class="sideblock-recipe__title">
            AXIS (ось)
        </div>
        <div class="sideblock-recipe__text">
            Ось показывает, в каком направлении поставить коррекцию астигматизма. Это угол от 0 до 180°, и он важен: даже при той же силе цилиндра, без правильной оси линза будет бесполезна.
        </div>

        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__table-item-column-icon">
            <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
        </div>
        <div class="sideblock-recipe__title">
            PD (межзрачковое расстояние)
        </div>
        <div class="sideblock-recipe__text">
            Измеряется в миллиметрах и является ориентиром для установки линз — важно, чтобы центры линз совпадали с точками вашего взгляда. Если PD ошибочно, то даже с идеальной линзой может быть некомфортно.
        </div>
        <div class="sideblock-recipe__text">
            Может быть общим значением для обоих глаз или же для каждого глаза по отдельности. В различных рецептах можно увидеть разные обозначения — DP, DPP или РЦ.
        </div>

        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__table-item-column-icon">
            <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
        </div>
        <div class="sideblock-recipe__title">
            ADD (аддидация)
        </div>
        <div class="sideblock-recipe__text">
            Это дополнительная коррекция к основной силе линзы, которая помогает видеть чётко вблизи при возрастных изменениях зрения (пресбиопии).
        </div>
        <div class="sideblock-recipe__text">
            То есть, если:
            <ul>
                <li>SPH отвечает за зрение вдаль,</li>
                <li>то ADD — это "добавка" для чтения, телефона и других мелочей на близком расстоянии.</li>
            </ul>
        </div>

        <div class="sideblock-recipe__spacer" />
        <div class="sideblock-recipe__line" />
        <div class="sideblock-recipe__spacer" />

        <div class="sideblock-recipe__text">
            (づ ◕‿◕ )づ Обратите внимание, что рецепт на очки не подходит для подбора контактных линз — для них нужен отдельный рецепт.
        </div>
        <div class="sideblock-recipe__text">
            Контактные линзы сидят прямо на глазу, а не на расстоянии, как очки, поэтому параметры у них немного другие: сила, радиус кривизны и даже посадка — всё учитывается по-другому.
        </div>
        <div class="sideblock-recipe__text">
            Мы за то, чтобы глазам было комфортно — в любых форматах. И всегда готовы помочь расшифровать рецепт или выбрать линзы.
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'sideblock-recipe',
    computed: {
        eyeExamInfoHref() {
            return config.urls.eyeExamInfo;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-recipe {
    .mixin-sideblock();

    padding: 20px 0 60px;
    &__table {
        margin-bottom: 40px;
        &-item {
            display: flex;
            width: 100%;
            &-column {
                flex: 0 0 auto;
                padding: 28px 20px 24px;
                width: 50%;
                &:first-child:last-child {
                    width: 100%;
                }
                &:first-child:last-child &-text {
                    max-width: 100%;
                }
                &-icon {
                    //height: 4rem;
                    //margin-bottom: 10px;
                    height: 48px;
                    margin-bottom: 16px;
                    padding: 0 20px;
                }
                &-text {
                    max-width: 150px;
                }
            }
        }
    }
    &__title {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: 8px;

        text-transform: uppercase;
    }
    &__text {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: 20px;

        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }

        ul {
            padding-left: 26px;
        }
    }
    &__trigger {
        .mixin-link-decoration();

        cursor: pointer;
    }
    &__spacer {
        margin-top: 40px;
    }
    &__line {
        width: 100%;
        border-top: 1px solid @color-gray-darkest;
        margin-bottom: 20px;
    }
    @media @media-sm-down {
        &__table {
            margin-bottom: 40px;
        }
    }
}
</style>
