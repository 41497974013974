<template>
    <transition name="fade">
        <div class="sideblock-root"
            v-if="isActive"
            >
            <div class="sideblock-root__background"
                v-if="isBackgroundNeeded"
            />
            <transition-group name="from-right"
                v-on:leave="deactivate"
                appear
                >
                <div class="sideblock-root__item"
                    v-for="(sideblock, index) in list"
                    v-bind:key="index + sideblock.name"
                    v-bind:style="{ zIndex: index }"
                    >
                    <div class="sideblock-root__item-overlay"
                        v-if="!sideblock.props.noOverlay || list.length > 1"
                        v-bind:class="{
                            _clickable: !sideblock.props.noOverlayAction,
                        }"
                        v-on:click="!sideblock.props.noOverlay && !sideblock.props.noOverlayAction && close()"
                    />
                    <div class="sideblock-root__item-container"
                        v-bind:key="index + sideblock.name"
                        >
                        <div class="sideblock-root__item-container-content">
                            <div class="sideblock-root__item-container-content-headline"
                                v-if="!sideblock.props.noHeadline"
                                >
                                <div class="sideblock-root__item-container-content-headline-back"
                                    v-on:click="close"
                                    >
                                    ←
                                </div>
                                <div class="sideblock-root__item-container-content-headline-title"
                                    v-if="config[sideblock.name] && config[sideblock.name].title"
                                    v-text="config[sideblock.name].title"
                                />
                                <div class="sideblock-root__item-container-content-headline-close"
                                    v-on:click="close"
                                    >
                                    <icon name="close" />
                                </div>
                            </div>
                            <div class="sideblock-root__item-container-content-main"
                                ref="main"
                                >
                                <component
                                    v-bind:is="'sideblock-' + sideblock.name"
                                    v-bind="sideblock.props"
                                    v-on:scroll-top="scrollTop"
                                />
                            </div>
                            <div class="sideblock-root__item-container-content-botline"
                                v-if="config[sideblock.name] && config[sideblock.name].button"
                                >
                                <ui-button
                                    v-on:click="close"
                                    v-text="config[sideblock.name].button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'sideblock-root',
    data: () => ({
        isActive: false,
        isBackgroundNeeded: false,
        config: {
            'cart': {
                title: 'Корзина',
            },
            'delivery': {
                title: 'Как работает примерка',
                button: 'Классно  ⁎⁺˳✧༚',
            },
            'delivery-old': {
                title: 'Доставка и возврат',
                button: 'Классно  ⁎⁺˳✧༚',
            },
            'fitting': {
                title: 'Примерить дома',
            },
            'availability': {
                title: 'Наличие в магазинах',
            },
            'lens': {
                title: 'Установка линз',
            },
            'materials': {
                title: 'Материал',
            },
            'prescription': {
                title: 'Загрузить рецепт',
            },
            'sizes-guide': {
                title: 'Размер',
            },
            'recipe': {
                title: 'Рецепт',
            },
        },
        defaults: {
            'select-lens': {
                noHeadline: true,
            },
        },
    }),
    computed: {
        list() {
            return this.$store.getters['sideblocks/list'].map(m => {
                if (this.defaults[m.name] !== undefined) {
                    m.props = {
                        ...this.defaults[m.name],
                        ...m.props,
                    };
                }
                return m;
            });
        },
        cartItems() {
            return this.$store.getters['cart/items'];
        },
    },
    methods: {
        close() {
            this.$store.commit('sideblocks/pop');
        },
        deactivate() {
            if (this.list.length === 0) {
                this.isActive = false;
            }
        },
        scrollTop() {
            const ref = this.$refs.main[this.list.length - 1];
            if (!ref || !ref.scroll) {
                return;
            }
            if ('scrollBehavior' in document.documentElement.style) {
                ref.scroll({ top: 0, behavior: 'smooth' });
            } else {
                ref.scroll(0, 0);
            }
        },
        closer() {
            this.$store.commit('sideblocks/pop');
            window.removeEventListener('hashchange', this.closer);
        },
    },
    watch: {
        list(newList, oldList) {
            const sideblocksAmountDifference = newList.length - oldList.length;
            if (sideblocksAmountDifference > 0) {
                this.$store.commit('common/modifyBodyFixedCounter', sideblocksAmountDifference);
                this.isActive = true;
                this.isBackgroundNeeded = !this.list.every(x => x.props.noOverlay);
                if (window.innerWidth < 1024) {
                    const sideblockHash = '#sibeblock-opened';
                    if (window.location.hash === sideblockHash) {
                        window.history.pushState('', '', window.location.origin + window.location.pathname + window.location.search);
                    }
                    window.history.pushState('', '', window.location.origin + window.location.pathname + window.location.search + sideblockHash);
                    window.addEventListener('hashchange', this.closer);
                }
            } else if (sideblocksAmountDifference < 0) {
                if (window.innerWidth < 1024) {
                    window.removeEventListener('hashchange', this.closer);
                    window.location.hash = '';
                }
                this.$store.commit('common/modifyBodyFixedCounter', sideblocksAmountDifference);
            }
        },
        cartItems(newVal) {
            if (newVal.length === 0) {
                this.config.cart.title = 'Корзина';
            } else {
                this.config.cart.title = `В корзине (${newVal.length})`;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-root {
    .typography-body-md();
    .transition-fade();

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @z-index-sideblock;

    overflow: hidden;
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: fade(@color-gray-darkest, 25%);
    }
    &__item {
        .transition-from-right();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .cms-toolbar-expanded & {
            top: @cms-toolbar-height;
        }
        &-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            &._clickable {
                cursor: pointer;
            }
        }
        &-container {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: stretch;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            &-content {
                position: relative;
                z-index: 2;

                flex: 0 1 auto;
                display: flex;
                flex-direction: column;
                align-content: stretch;
                justify-content: flex-start;

                background-color: @color-gray-lightest;

                box-shadow: 0 0 100px 0 @color-accent-cold;
                &-progress {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                }
                &-headline {
                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-height: 60px;
                    border-bottom: 1px solid @color-gray-darkest;
                    &-back {
                        flex: 0 0 auto;
                        padding-left: 20px;
                        padding-right: 20px;

                        font-size: 2.4rem;
                        line-height: 2.4rem;

                        cursor: pointer;
                    }
                    &-title {
                        flex: 1 1 auto;
                        padding: 20px 62px 20px 0;

                        text-align: center;
                        text-transform: uppercase;
                    }
                    &-close {
                        display: none;
                    }
                }
                &-main {
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    align-content: flex-start;
                    justify-content: flex-start;
                    overflow: auto;
                }
                &-botline {
                    display: none;
                }
            }
        }
    }
    @media @media-md-down {
        &__item {
            &-container {
                padding: 0;
                &-content {
                    flex: 1 1 auto;
                    &-headline {
                        min-height: 48px;
                        &-back {
                            display: none;
                        }
                        &-title {
                            padding: 5px 20px 3px 68px;
                        }
                        &-close {
                            flex: 0 0 auto;
                            align-self: stretch;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 48px;
                            min-height: 48px;

                            background-color: @color-gray-main;

                            font-size: 1rem;
                            text-align: center;

                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__item {
            &-container {
                padding: 0;
                &-content {
                    &-botline {
                        flex: 0 0 auto;
                        display: block;
                        padding: 20px;
                    }
                }
            }
        }
    }
}
</style>
