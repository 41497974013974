<template>
    <div class="page-checkout-auth">
        <div class="page-checkout-auth__headline"
            v-if="user"
            >
            Привет{{user.firstName ? ', ' + user.firstName : ''}}! <span
                class="page-checkout-auth__headline-trigger"
                v-text="'Выйти'"
                v-on:click="logout"
            />
        </div>
        <div class="page-checkout-auth__headline"
            v-else
            >
            Уже покупали у нас? <span
                class="page-checkout-auth__headline-trigger"
                v-text="'Войдите,'"
                v-on:click="showAuthModal"
            />
            чтобы применить вашу скидку по программе лояльности.
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-checkout-auth',
    computed: {
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        user() {
            return this.$store.getters['user/user'];
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('user/logout');
        },
        showAuthModal() {
            this.$store.commit('modals/push',
                this.checkoutData.phone
                    ? {
                        name: 'auth',
                        props: {
                            phone: this.checkoutData.phone,
                        },
                    }
                    : 'auth',
            );
        },
    },
    // mounted() {
    //     if (this.checkoutData.phone) {
    //         this.fields.email.isAutofocus = false;
    //         this.fields.email.value = this.checkoutData.email;
    //         this.fields.password.isAutofocus = true;
    //     }
    // },
    // watch: {
    //     user(newVal) {
    //         if (newVal !== null && newVal.email) {
    //             this.fields.email.value = newVal.email;
    //             this.$store.commit('checkout/updateData', {
    //                 email: newVal.email,
    //             });
    //         }
    //     },
    // },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-auth {
    .typography-body-md();

    &__headline {
        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }
    }
}
</style>
