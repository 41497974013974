export const api = {
    areas: {
        list: '/shop/areas/',
    },
    baseUrl: '/',
    certificates: {
        checkBalance: '/shop/gift-voucher-check/',
    },
    collections: {
        list: '/collection/',
        pages: '/collection/page/', // depricated
        menu: '/collection/menu',
    },
    dadata: {
        suggest: '/shop/address_suggest/',
    },
    shop: {
        products: '/shop/products/', // products api
        basket: { // basket api
            get: '/shop/basket/',
            update: '/shop/basket/',
            add: '/shop/basket/add-product/',
        },
        search: '/search/preview/',
        stores: '/shop/stocks/',
        paymentMethods: '/shop/checkout/payment-methods/',
        shippingMethods: '/shop/shipping_methods/',
        // getDeliveryCode: '/shop/delivery_code_suggest/',
        getDeliveryCode: '/shop/cdek_code/',
        shippingPrice: '/shipping/calculate/',
        checkout: '/shop/checkout/',
        filters: '/shop/filters/{collectionId}/',
        promocode: {
            apply: '/shop/basket/add-voucher/',
            remove: '/shop/basket/delete-voucher/',
        },
        cabinet: {
            orders: '/shop/cabinet/order/', // get orders
        },
        lenses: {
            list: '/shop/lenses/',
        },
        prescription: '/shop/prescription/',
    },
    sms: {
        get: '/accounts/verification/sms/',
        check: '/accounts/verification/sms/check/',
    },
    subscribe: '/subscribe/',
    user: {
        checkEmail: '/accounts/email_exist/', // check if email exist
        auth: '/accounts/login/',
        logout: '/accounts/logout/',
        update: '/accounts/update/',
        info: {
            get: '/api/user/',
            update: '/accounts/info/update/',
        },
        password: '/accounts/password/',
        resetPassword: '/accounts/reset/',
        setPassword: '/accounts/reset_complete/',
    },
    communication: {
        list: '/shop/communication/',
    },
};

export const bodyAttributes = {
    loaded: 'data-app-loaded',
};

export const cart = {
    freeShippingThreshold: 5000,
    calculatableShippingMethods: [
        'cdek',
    ],
    keys: {
        common: 'cart',
        fitting: 'fitting',
    },
};

export const currencies = {
    rub: {
        code: 'RUB',
        symbol: '₽',
        name: 'Рубли',
    },
};

export const customGiftcardsLimits = {
    digital: {
        min: 500,
        max: 50000,
    },
    paper: {
        min: 500,
        max: 50000,
    },
};

export const dadata = {
    // enabledCountries: [ 'Россия', 'Беларусь' ],
    enabledCountries: [],
};

export const media = {
    markers: {
        genders: {
            male: 'm',
            female: 'f',
        },
    },
    defaultAspectRatio: 1.227,
    aspectRatioMobile: 0.8,
    sizes: {
        images: {
            small: 280,
            medium: 1024,
            large: 1500,
            extra_large: 2000,
        },
        videos: {
            small: 240,
            medium: 480,
            large: 720,
            extra_large: 1080,
        },
    },
};

export const filters = {
    basicFilterSetsCodes: [ 'form', 'material', 'size', 'color', 'blackout' ],
    gender: {
        code: 'gender',
        values: {
            male: { code: 'male', id: 10 },
            female: { code: 'female', id: 11 },
        },
    },
    opticsType: {
        code: 'opticsType',
        values: {
            optics: { code: 'optics', id: 14 },
            sun: { code: 'sun', id: 15 },
        },
    },
};

export const fitting = {
    validCountries: [ 'Россия', 'Беларусь' ],
    validCities: [ 'Санкт-Петербург', 'Москва' ],
    shippingPrefix: 'fitting_',
    voucher: 'fitting_56AtkzuFWFeHeJMQ',
};

export const localStorage = {
    prefix: 'pyeoptics',
    checkoutSuccessAffix: 'CheckoutSuccessV3',
    gtmImpressionSourceAffix: 'gtmImpressionSource',
    affixes: {
        callbacks: 'CallbacksStack',
        sms: {
            lastRequestDate: 'LastRequestDate',
        },
        checkout: {
            savedData: 'SavedDataV2',
            phoneConfirmation: 'PhoneConfirmation',
        },
        lastViewedItem: 'CatalogLastViewedItem',
        lastPromotionId: 'LastPromotionId',
        lastPromotionName: 'lastPromotionName',
    },
};

export const translations = [
];

export const urls = {
    cabinet: '/cabinet',
    cart: '/shop/cart/cart',
    cliponInfo: 'https://clipons.pyeoptics.com/',
    fittingInfo: '/pages/hometryon',
    lensInfo: '/pages/lens/',
    fitting: '/shop/cart/fitting',
    catalog: '/solncezaschitnye-ochki/vse-solncezaschitnye-ochki/?filter0=15',
    catalogAccessories: '/accessories-collections/',
    catalogOptical: '/opravy-ochki-dlya-zreniya/vse-ochki-opravy/?filter0=14',
    eyeExamInfo: '/pages/eye-test/',
    checkoutSuccess: '/shop/checkout/success',
    oferta: '/pages/oferta/',
    policy: '/pages/privacy-policy/',
    passwordReset: '/accounts/reset',
    refund: '/pages/refund/',
    shops: '/shop/stores',
    warranty: '/pages/warranty/',
};

export default {
    api,
    bodyAttributes,
    cart,
    currencies,
    customGiftcardsLimits,
    dadata,
    media,
    filters,
    fitting,
    localStorage,
    translations,
    urls,
};
