<template>
    <div class="page-stores-item">
        <a class="page-stores-item__image"
            v-if="!isMapActive"
            v-bind:href="href"
            v-bind:style="imageStyle"
        />
        <div class="page-stores-item__map"
            v-bind:class="{ '_active': isMapActive }"
            >
            <div class="page-stores-item__map-content">
                <iframe frameborder="0"
                    style="border:0"
                    v-bind:src="item.map_src"
                    allowfullscreen
                />
            </div>
        </div>
        <div class="page-stores-item__info">
            <div class="page-stores-item__info-title" v-text="item.name" />
            <div class="page-stores-item__info-place">
                <div class="page-stores-item__info-place-city" v-text="item.area.name" />
                <div class="page-stores-item__info-place-address">
                    {{ item.address }}
                    <br />
                    {{ item.work_time }}
                    <template v-if="item.eye_exam">
                        <br />
                        Проверка зрения <a v-bind:href="item.eye_exam"
                            target="_blank"
                            v-text="'по записи'"
                        />
                    </template>
                    <template v-else-if="item.no_eye_exam">
                        <div v-html="item.no_eye_exam" class="common-ckeditor"></div>
                    </template>
                </div>
            </div>
            <div class="page-stores-item__info-other">
                <div class="page-stores-item__info-other-map">
                    <div class="page-stores-item__info-other-map-text"
                        v-on:click="toggleMap"
                        v-text="isMapActive ? 'Фото' : 'Карта'"
                    />
                </div>
                <div class="page-stores-item__info-other-phone">
                    <a class="page-stores-item__info-other-phone-text"
                        v-bind:href="phoneLink"
                        v-text="item.contacts"
                    />
                </div>
                <div class="page-stores-item__info-other-more">
                    <a class="page-stores-item__info-other-more-text"
                        v-bind:href="href"
                        v-text="'Узнать о магазине больше →'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-stores-item',
    props: ['item'],
    data: () => ({
        isMapActive: false,
    }),
    computed: {
        href() {
            return `${config.urls.shops}/${this.item.id}`;
        },
        phoneLink() {
            const digits = this.item?.contacts?.replace(/\D/g, '');
            if (digits.length !== 11) return null;
            return `tel:+${digits}`;
        },
        imageStyle() {
            if (!this.item?.image) return null;

            const resize = utils.common.getResize({
                width: 50,
                aspectRatio: 2 / 3,
                breakpoints: {
                    1023: { width: 100 },
                },
            });
            return {
                backgroundImage: `url('${utils.common.injectResize(this.item.image, resize)}')`,
            };
        },
    },
    methods: {
        toggleMap() {
            this.isMapActive = !this.isMapActive;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-stores-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    &__image {
        flex: 0 0 auto;
        display: block;
        width: 100%;
        padding-top: 66.66%;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__map {
        flex: 0 0 auto;

        position: relative;
        z-index: 1;

        display: none;
        width: 100%;
        padding-top: 66.66%;
        &._active {
            display: block;
        }
        &-content {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: 100%;
            height: 100%;

            & > iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__info {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding: 40px;
        &-title {
            margin-bottom: 30px;

            font-size: 2.8rem;
            line-height: 1.2;
            text-transform: uppercase;
        }
        &-place {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            &-city {
                flex: 0 0 auto;
                width: 160px;
                padding-right: 20px;

                text-transform: uppercase;
            }
            &-address {
                flex: 1 1 auto;
            }
        }
        &-other {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            width: 100%;
            margin-top: auto;
            margin-bottom: 0;
            &-map {
                flex: 0 0 auto;
                width: 160px;
                padding-right: 20px;
                &-text {

                    .mixin-link-decoration();

                    cursor: pointer;
                    /*&:hover {
                        text-decoration: none;
                    }*/
                }
            }
            &-phone {
                flex: 1 1 auto;
                padding-right: 20px;
                margin-left: 0;
                margin-right: auto;
            }
            &-more {
                flex: 1 1 auto;
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
    @media @media-md-down {
        &__info {
            &-place {
                flex-direction: column;
                margin-bottom: 0;
                &-city {
                    margin-bottom: 20px;

                    text-transform: none;
                }
            }
            &-other {
                position: relative;

                flex-wrap: wrap;
                &-map {
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    flex: 0 0 auto;
                    width: auto;
                    padding-right: 0;
                }
                &-phone {
                    order: -1;
                    flex: 1 1 auto;
                    margin-top: 20px;
                }
                &-more {
                    flex: 1 1 100%;
                    margin-top: 20px;
                }
            }
        }
    }
}
</style>
